import Link from "next/link";
import Footer from "../components/Footer";
import PodHead from "../components/Head";
import Navbar from "../components/Navbar";
import ApplePodcastsTile from "../components/UI/podcast_tiles/ApplePodcastsTile";
import GooglePodcastsTile from "../components/UI/podcast_tiles/GooglePodcastsTile";
import SpotifyPodcastsTile from "../components/UI/podcast_tiles/SpotifyPodcastsTile";

const NotFoundPage = () => {
  return (
    <>
      <PodHead title="Not Found" />
      <main className="h-d-screen flex bg-gradient-to-t from-orange-400 via-orange-300 to-indigo-500">
        <div className="fixed left-0 right-0 top-0 z-10 w-full">
          <Navbar showLogo />
        </div>
        <div
          className="flex grow items-center justify-center bg-base-100/50 p-0 backdrop-blur-sm md:p-8"
          id="episodes"
        >
          <div className="mx-4 max-w-prose rounded-xl bg-neutral p-8 text-center text-accent shadow-xl">
            <h1 className="text-bold mb-4 text-center text-3xl">
              We couldn&apos;t find what you were looking for!
            </h1>
            <p>
              But why bother continuing your search when we have what you need
              right here. Check us out on the platforms below&nbsp;
              <Link href="episodes" className="link-primary link">
                or listen to us here
              </Link>{" "}
            </p>
            <div className="mt-4 flex flex-row flex-wrap justify-center gap-2">
              <a
                className="transition delay-150 duration-300 ease-in-out hover:scale-110 motion-reduce:transition-none motion-reduce:hover:transform-none"
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/show/5sTfLG6GhzJ1SYDY3kWLMR"
              >
                <SpotifyPodcastsTile />
              </a>
              <a
                className="transition delay-150 duration-300 ease-in-out hover:scale-110 motion-reduce:transition-none motion-reduce:hover:transform-none"
                target="_blank"
                rel="noreferrer"
                href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xOTgxNTE5LnJzcw=="
              >
                <GooglePodcastsTile />
              </a>
              <a
                className="transition delay-150 duration-300 ease-in-out hover:scale-110 motion-reduce:transition-none motion-reduce:hover:transform-none"
                target="_blank"
                rel="noreferrer"
                href="https://podcasts.apple.com/podcast/id1621708943"
              >
                <ApplePodcastsTile />
              </a>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 z-10 w-full transition-opacity">
          <Footer />
        </div>
      </main>
    </>
  );
};

export default NotFoundPage;
